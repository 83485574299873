<template>
    <v-container>
        <v-card max-width="1200" class="mx-auto pa-5">
            <v-card-title class="text-h5 ma-0 pa-0">
                Offene Abrechnungen
            </v-card-title>
            <template v-if="list.length" class="mx-auto pa-5">
                <v-table name="table" max-width="1200" class="ta text-truncate">
                    <thead>
                        <tr>
                            <th class="text-left" style="width: 35%">
                                Wettspiel
                            </th>
                            <th class="text-left" style="width: 10%">
                                Turnier Startdatum
                            </th>
                            <th class="text-left" style="width: 10%">
                                Turnier Enddatum
                            </th>
                            <th class="text-left" style="width: 20%">
                                Referee
                            </th>
                            <th class="text-left" style="width: 20%">
                                E-Mail
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(listItem,i) in list" :key="listItem.competition + listItem.referee+ i">
                            <td>{{ listItem.competition }}</td>
                            <td>{{ listItem.start }}</td>
                            <td>{{ listItem.end }}</td>
                            <td>{{ listItem.referee }}</td>
                            <td>{{ listItem.email }}</td>
                        </tr>
                    </tbody>
                </v-table>
            </template>
            <template v-else>
                <section class="text-center d-flex flex-column align-center justify-center">
                    <p>Abrufen der Inhalte</p>
                    <v-progress-circular indeterminate color="primary"></v-progress-circular>
                </section>
            </template>
        </v-card>
    </v-container>
</template>
  
<script>
import axios from "axios";

export default {
    name: "Abrechnungen",
    data() {
        return {
            backend: process.env.VUE_APP_STRAPI_API_URL,
            referees: [],
            list: [],
        };
    },
    async created() {

        this.userId = this.parseJwt(localStorage.getItem("token")).id;

        let config = {
            method: "get",
            maxBodyLength: Infinity,
            // strapi 3 filtering
            // https://docs-v3.strapi.io/developer-docs/latest/developer-resources/content-api/content-api.html#filter
            url: `${this.backend}/users?_limit=-1`,
            // url: `${this.backend}/users?role.name=referee&_limit=-1`, // direkt filtern CW
            headers: {
                Authorization: localStorage.getItem("token") || "",
            },
        };

        await axios
            .request(config)
            .then(async (response) => {

                this.referees = []
                response.data.forEach(user => {
                    if (user.role.name == "referee") {
                        this.referees.push(user)
                    }
                });
                await this.fetchCompetitionsOfReferees()
            })
            .catch((error) => {
                console.log(error);
            });

    },
    methods: {
        async fetchCompetitionsOfReferees() {

            this.list = []
            const yourDate = new Date().toISOString().split("T")[0];

          console.log(this.referees)
            for (const referee of this.referees) {

                const config = {
                    method: "get",
                    maxBodyLength: Infinity,
                    // strapi 3 filtering
                    // https://docs-v3.strapi.io/developer-docs/latest/developer-resources/content-api/content-api.html#filters
                    url: `${this.backend}/competitions?_where[0][competitionEndDate_lt]=${yourDate}&_where[1][referees.id_eq]=${referee._id}`,
                    headers: {
                        Authorization: localStorage.getItem("token") || "",
                    },
                };

                await axios
                    .request(config)
                    .then((response) => {
                        if (response.data && response.data.length) {
                            for (const competition of response.data) {
                                if (competition.abrechnungens && competition.abrechnungens.length >= 1) {
                                    competition.abrechnungens.forEach((abrechnung) => {
                                        if (abrechnung.users_permissions_user == referee._id) {
                                            console.debug("already submitted")
                                        } else {
                                            this.pushIntoList(competition, referee)
                                        }
                                    });
                                } else {
                                    this.pushIntoList(competition, referee)
                                }
                            }
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            }
        },
        pushIntoList(competition, referee) {
            this.list.push({
                competition: competition.name,
                start: competition.competitionStartDate,
                end: competition.competitionEndDate,
                referee: referee.username,
                email: referee.email,
            })
        },
        parseJwt(token) {
            var base64Url = token.split(".")[1];
            var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
            var jsonPayload = decodeURIComponent(
                window
                    .atob(base64)
                    .split("")
                    .map(function (c) {
                        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
                    })
                    .join("")
            );

            return JSON.parse(jsonPayload);
        },
    },
};
</script>

<style>
.ta table,
th,
td {
    border-bottom: 1px solid grey;
}
</style>